<div fxLayout="row" fxLayoutAlign="end" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_FINANCIALACTIVITYACCOUNT'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;Define New Mapping
  </button>
</div>

<div class="mat-elevation-z8 container">

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="financialActivity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Financial Activity </th>
      <td mat-cell *matCellDef="let financialActivityAccount"> {{ financialActivityAccount.financialActivityData.name }} </td>
    </ng-container>

    <ng-container matColumnDef="glAccountName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Name </th>
      <td mat-cell *matCellDef="let financialActivityAccount"> {{ financialActivityAccount.glAccountData.name }} </td>
    </ng-container>

    <ng-container matColumnDef="glAccountCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Code </th>
      <td mat-cell *matCellDef="let financialActivityAccount"> {{ financialActivityAccount.glAccountData.glCode }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['view', row.id]" class="select-row"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[10, 25]" showFirstLastButtons></mat-paginator>

</div>
