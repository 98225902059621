<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <button mat-raised-button color="primary" [routerLink]="['/system', 'roles-and-permissions']">
    <fa-icon icon="check"></fa-icon>&nbsp;&nbsp;
    Permissions
  </button>
  <button mat-raised-button color="primary" (click)="changeUserPassword()">
    <fa-icon icon="cog"></fa-icon>&nbsp;&nbsp;
    Change Password
  </button>
</div>


<div class="container" fxLayout="column" fxLayoutGap="2%">

  <mat-card>

    <div fxLayout="row wrap" class="content">

      <div fxFlex="50%" class="header">
        User Id
      </div>

      <div fxFlex="50%">
        {{ profileData.userId }}
      </div>

      <div fxFlex="50%" class="header">
        User Name
      </div>

      <div fxFlex="50%">
        {{ profileData.username }}
      </div>

      <div fxFlex="50%" class="header">
        Office
      </div>

      <div fxFlex="50%">
        {{ profileData.officeName }}
      </div>

      <div fxFlex="50%" class="header">
        Status
      </div>

      <div fxFlex="50%">
        {{ profileData.authenticated ? 'Authenticated' : 'Not Authenticated' }}
      </div>

      <div fxFlex="50%" class="header">
        Language
      </div>

      <div fxFlex="50%">
        {{ language }}
      </div>

    </div>

  </mat-card>

  <mat-card>

    <table class="mat-elevation-z1" mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef> Role </th>
        <td mat-cell *matCellDef="let role"> {{ role.name }} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let role">{{ role.description }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

  </mat-card>

</div>