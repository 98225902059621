<h1 mat-dialog-title> Error Log </h1>

<div mat-dialog-content>

    <p> {{ data }}</p>

</div>

<mat-dialog-actions align="left">
    <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
