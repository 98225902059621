<h1 mat-dialog-title>View Journal Entry</h1>

<div mat-dialog-content>

  <table>

    <tr>
      <th>Office</th>
      <td>{{ data.journalEntry.officeName }}</td>
    </tr>

    <tr>
      <th>Entry ID</th>
      <td>{{ data.journalEntry.id }}</td>
    </tr>

    <tr>
      <th>Transaction ID</th>
      <td>{{ data.journalEntry.transactionId }}</td>
    </tr>

    <tr>
      <th>Transaction Date</th>
      <td>{{ data.journalEntry.transactionDate }}</td>
    </tr>

    <tr>
      <th>Type</th>
      <td>{{ data.journalEntry.glAccountType.value }}</td>
    </tr>

    <tr>
      <th>Account Code</th>
      <td>{{ data.journalEntry.glAccountId }}</td>
    </tr>

    <tr>
      <th>Account Name</th>
      <td>{{ data.journalEntry.glAccountName }}</td>
    </tr>

    <tr>
      <th>
        <span *ngIf="data.journalEntry.entryType.value === 'CREDIT'">Credit</span>
        <span *ngIf="data.journalEntry.entryType.value === 'DEBIT'">Debit</span>
      </th>
      <td>
        {{ (data.journalEntry.currency.displaySymbol || data.journalEntry.currency.code) + ' ' + data.journalEntry.amount }}
      </td>
    </tr>

    <tr *ngIf="data.journalEntry.referenceNumber">
      <th>Reference Number</th>
      <td>{{ data.journalEntry.referenceNumber }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.comments">
      <th>Comments</th>
      <td>{{ data.journalEntry.comments }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails">
      <th colspan="2" class="header">Payment Details</th>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.paymentType">
      <th>Payment Type</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.paymentType.name }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.accountNumber">
      <th>Account Number</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.accountNumber }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.checkNumber">
      <th>Cheque Number</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.checkNumber }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.routingCode">
      <th>Routing Code</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.routingCode }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.receiptNumber">
      <th>Receipt Number</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.receiptNumber }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.bankNumber">
      <th>Bank Number</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.bankNumber }}</td>
    </tr>

    <tr>
      <th>Created by</th>
      <td>{{ data.journalEntry.createdByUserName }}</td>
    </tr>

    <tr>
      <th>Created on</th>
      <td>{{ data.journalEntry.createdDate }}</td>
    </tr>

  </table>

</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
