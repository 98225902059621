<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['data-tables']">
            <mat-icon matListIcon>
              <fa-icon icon="table" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Manage Data tables</h4>
            <p matLine>Add new extra fields to any entity in the form of data table</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['codes']">
            <mat-icon matListIcon>
              <fa-icon icon="list-ul" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Manage Codes</h4>
            <p matLine>Codes are used to define drop down values</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['roles-and-permissions']">
            <mat-icon matListIcon>
              <fa-icon icon="key" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Manage Roles and Permissions</h4>
            <p matLine>Define or modify roles and associated permissions</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['configure-mc-tasks']">
            <mat-icon matListIcon>
              <fa-icon icon="sitemap" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Configure Maker Checker Tasks</h4>
            <p matLine>Define or modify Maker Checker tasks</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['hooks']">
            <mat-icon matListIcon>
              <fa-icon icon="anchor" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Manage Hooks</h4>
            <p matLine>Define Hooks</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['entity-to-entity-mapping']">
            <mat-icon matListIcon>
              <fa-icon icon="road" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Entity to Entity Mapping</h4>
            <p matLine>Define or modify entity to entity mappings</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['surveys']">
            <mat-icon matListIcon>
              <fa-icon icon="file-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Manage Surveys</h4>
            <p matLine>Manage your Services</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['audit-trails']">
            <mat-icon matListIcon>
              <fa-icon icon="money-check" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Audit Trails</h4>
            <p matLine>Audit logs of all the activities, such as create client, disburse loans etc</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['reports']">
            <mat-icon matListIcon>
              <fa-icon icon="file-word" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Manage Reports</h4>
            <p matLine>Add new report and classify reports</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['scheduler-jobs']">
            <mat-icon matListIcon>
              <fa-icon icon="clock" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Scheduler Jobs</h4>
            <p matLine>Schedule a job, modify or delete jobs</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['global-configurations']">
            <mat-icon matListIcon>
              <fa-icon icon="cogs" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Configurations</h4>
            <p matLine>Global configurations and Cache settings</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['account-number-preferences']">
            <mat-icon matListIcon>
              <fa-icon icon="key" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Account Number Preferences</h4>
            <p matLine>Preferences for generating account numbers for client, loan and savings accounts</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['external-services']">
            <mat-icon matListIcon>
              <fa-icon icon="cog" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>External Services</h4>
            <p matLine>External Services Configuration</p>
          </mat-list-item>

          <mat-list-item>
            <mat-icon matListIcon>
              <fa-icon icon="key" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Two-Factor Configuration</h4>
            <p matLine>Two-factor authentication configuration settings</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>
