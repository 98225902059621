<div fxLayout="row wrap" fxLayout.lt-md="column">

  <h3 class="mat-h3" fxFlexFill>General</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">Name</span>
    <span fxFlex="60%" *ngIf="client.legalFormId === 2">{{ client.fullname }}</span>
    <span fxFlex="60%" *ngIf="client.legalFormId === 1">{{ client.firstname }} {{ client.middlename ? client.middlename + ' ' + client.lastname : client.lastname }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Office</span>
    <span fxFlex="60%">{{ client.officeId | find:clientTemplate.officeOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Legal Form</span>
    <span fxFlex="60%">{{ client.legalFormId | find:clientTemplate.clientLegalFormOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.staffId">
    <span fxFlex="40%">Staff</span>
    <span fxFlex="60%">{{ client.staffId | find:clientTemplate.staffOptions:'id':'displayName' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.dateOfBirth">
    <span fxFlex="40%">{{ client.legalFormId === 1 ? 'Date of Birth' : 'Incorporation Date' }}</span>
    <span fxFlex="60%">{{ client.dateOfBirth | date }}</span>
  </div>

  <div fxFlexFill *ngIf="client.externalId">
    <span fxFlex="40%">External Id</span>
    <span fxFlex="60%">{{ client.externalId }}</span>
  </div>

  <div fxFlexFill *ngIf="client.mobileNo">
    <span fxFlex="40%">Mobile No</span>
    <span fxFlex="60%">{{ client.mobileNo }}</span>
  </div>

  <div fxFlexFill *ngIf="client.clientTypeId">
    <span fxFlex="40%">Client Type</span>
    <span fxFlex="60%">{{ client.clientTypeId | find:clientTemplate.clientTypeOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.clientClassificationId">
    <span fxFlex="40%">Client Classification</span>
    <span fxFlex="60%">{{ client.clientClassificationId | find:clientTemplate.clientClassificationOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.savingsProductId">
    <span fxFlex="40%">Savings Product</span>
    <span fxFlex="60%">{{ client.savingsProductId | find:clientTemplate.savingProductOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.submittedOnDate">
    <span fxFlex="40%">Submitted On Date</span>
    <span fxFlex="60%">{{ client.submittedOnDate | date }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Active?</span>
    <span fxFlex="60%">{{ client.active ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.activationDate">
    <span fxFlex="40%">Activation Date</span>
    <span fxFlex="60%">{{ client.activationDate | date }}</span>
  </div>

  <ng-container *ngIf="client.legalFormId === 1">

    <div fxFlexFill *ngIf="client.genderId">
      <span fxFlex="40%">Gender</span>
      <span fxFlex="60%">{{ client.genderId | find:clientTemplate.genderOptions:'id':'name' }}</span>
    </div>
  
    <div fxFlexFill>
      <span fxFlex="40%">Is Staff?</span>
      <span fxFlex="60%">{{ client.isStaff ? 'Yes' : 'No' }}</span>
    </div>

  </ng-container>

  <ng-container *ngIf="client.legalFormId === 2">

    <div fxFlexFill *ngIf="client.clientNonPersonDetails.incorpValidityTillDate">
      <span fxFlex="40%">Incorporation Validity Till Date</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.incorpValidityTillDate | date }}</span>
    </div>
  
    <div fxFlexFill>
      <span fxFlex="40%">Incorportation Number</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.incorpNumber || 'Not Provided' }}</span>
    </div>
  
    <div fxFlexFill *ngIf="client.clientNonPersonDetails.mainBusinessLineId">
      <span fxFlex="40%">Main Business Line</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.mainBusinessLineId | find:clientTemplate.clientNonPersonMainBusinessLineOptions:'id':'name' }}</span>
    </div>
  
    <div fxFlexFill *ngIf="client.clientNonPersonDetails.constitutionId">
      <span fxFlex="40%">Constitution</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.constitutionId | find:clientTemplate.clientNonPersonConstitutionOptions:'id':'name'}}</span>
    </div>
  
    <div fxFlexFill>
      <span fxFlex="40%">Remarks</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.remarks || 'N/A' }}</span>
    </div>

  </ng-container>

  <div fxFlexFill *ngIf="client.familyMembers.length" fxLayout="row wrap" fxLayout.lt-md="column" class="m-b-20">

    <h3 class="mat-h3" fxFlexFill>Family Members</h3>

    <mat-divider fxFlexFill></mat-divider>

    <mat-accordion fxFlexFill class="m-t-5">

      <mat-expansion-panel *ngFor="let member of client.familyMembers" class="family-member">

        <mat-expansion-panel-header>

          <mat-panel-title>
            {{ member.firstName }} {{ member.middleName ? member.middleName + ' ' + member.lastName : member.lastName }}
          </mat-panel-title>

          <mat-panel-description>
            {{member.relationship}}
          </mat-panel-description>

        </mat-expansion-panel-header>

        <mat-divider></mat-divider>

        <p>
          First Name : {{ member.firstName }}<br />
          Middle Name : {{ member.middleName }}<br />
          Last Name : {{ member.lastName }}<br />
          Qualification : {{ member.qualification }}<br />
          Relationship : {{ member.relationshipId | find:clientTemplate.familyMemberOptions.relationshipIdOptions:'id':'name' }}<br />
          Age : {{ member.age }}<br />
          Is Dependent : {{ member.isDependent ? 'Yes' : 'No' }}<br />
          Marital Status : {{ member.maritalStatusId | find:clientTemplate.familyMemberOptions.maritalStatusIdOptions:'id':'name' }}<br />
          Gender : {{ member.genderId | find:clientTemplate.familyMemberOptions.genderIdOptions:'id':'name' }}<br />
          Profession : {{ member.professionId | find:clientTemplate.familyMemberOptions.professionIdOptions:'id':'name' }}<br />
          Date Of Birth : {{ member.dateOfBirth | date }}<br />
        </p>

      </mat-expansion-panel>

    </mat-accordion>    

  </div>

  <div fxFlexFill *ngIf="client.address.length" fxLayout="row wrap" fxLayout.lt-md="column">

    <h3 class="mat-h3" fxFlexFill>Address</h3>

    <mat-divider fxFlexFill></mat-divider>

    <mat-accordion fxFlexFill class="m-t-5">

      <mat-expansion-panel *ngFor="let address of client.address" class="address">
  
        <mat-expansion-panel-header>
  
          <mat-panel-title>
            {{ getSelectedValue('addressTypeIdOptions',address.addressTypeId)?.name }}
          </mat-panel-title>
  
          <mat-panel-description>
            {{address.relationship}}
          </mat-panel-description>
  
        </mat-expansion-panel-header>
  
        <mat-divider></mat-divider>
  
        <p>
          <span *ngIf="isFieldEnabled('street')">Street : {{address.street}}<br /></span>
          <span *ngIf="isFieldEnabled('addressLine1')">Address Line 1 : {{address.addressLine1}}<br /></span>
          <span *ngIf="isFieldEnabled('addressLine2')">Address Line 2 : {{address.addressLine2}}<br /></span>
          <span *ngIf="isFieldEnabled('addressLine3')">Address Line 3 : {{address.addressLine3}}<br /></span>
          <span *ngIf="isFieldEnabled('townVillage')">Town / Village : {{address.townVillage}}<br /></span>
          <span *ngIf="isFieldEnabled('city')">City : {{address.city}}<br /></span>
          <span *ngIf="isFieldEnabled('stateProvinceId')">State / Province :
            {{getSelectedValue('stateProvinceIdOptions',address.stateProvinceId)?.name}}<br /></span>
          <span *ngIf="isFieldEnabled('countryId')">Country :
            {{getSelectedValue('countryIdOptions',address.countryId)?.name}}<br /></span>
          <span *ngIf="isFieldEnabled('postalCode')">Postal Code : {{address.postalCode}}<br /></span>
          <span *ngIf="isFieldEnabled('isActive')">Active Status : {{address.isActive}}<br /></span>
        </p>
  
      </mat-expansion-panel>
  
    </mat-accordion>

  </div>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    Previous
  </button>
  <button mat-raised-button [routerLink]="['../']">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="submit.emit()">
    Submit
  </button>
</div>
