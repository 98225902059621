<div class="container">

  <mat-card>

    <div>

      <form [formGroup]="provisioningCriteriaForm">

        <div fxLayout="row" fxLayoutGap="2%">

          <mat-form-field fxFlex>
            <mat-label>Provisioning Criteria</mat-label>
            <input required matInput formControlName="criteriaName">
            <mat-error *ngIf="provisioningCriteriaForm.controls.criteriaName.hasError('required')">
              Provisioning Criteria is <strong>required</strong>
            </mat-error>
          </mat-form-field>
  
          <mat-form-field fxFlex>
            <mat-label>Selected Products</mat-label>
            <mat-select formControlName="loanProducts" multiple>
              <mat-option *ngFor="let product of loanProvisioningCriteriaTemplate.loanProducts" [value]="product">
                {{ product.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </form>

    </div>

    <div class="mat-elevation-z1">

      <mat-hint *ngIf="!provisioningCriteriaFormValid">Please fill all provisioning criteria definitions.</mat-hint>

      <table mat-table class="m-t-10" [dataSource]="definitions">

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef> Category </th>
          <td mat-cell *matCellDef="let definition"> {{ definition.categoryName }} </td>
        </ng-container>
  
        <ng-container matColumnDef="minAge">
          <th mat-header-cell *matHeaderCellDef> Min Age </th>
          <td mat-cell *matCellDef="let definition"> {{ definition.minAge || '0' }} </td>
        </ng-container>

        <ng-container matColumnDef="maxAge">
          <th mat-header-cell *matHeaderCellDef> Max Age </th>
          <td mat-cell *matCellDef="let definition"> {{ definition.maxAge || '0' }} </td>
        </ng-container>
  
        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef> Percentage (%)</th>
          <td mat-cell *matCellDef="let definition"> {{ definition.provisioningPercentage || '0' }} </td>
        </ng-container>

        <ng-container matColumnDef="liabilityAccount">
          <th mat-header-cell *matHeaderCellDef> Liability Account </th>
          <td mat-cell *matCellDef="let definition"> {{ (definition.liabilityAccount | find:loanProvisioningCriteriaTemplate.glAccounts:'id':'name') || '-' }} </td>
        </ng-container>
  
        <ng-container matColumnDef="expenseAccount">
          <th mat-header-cell *matHeaderCellDef> Expense Account </th>
          <td mat-cell *matCellDef="let definition"> {{ (definition.expenseAccount | find:loanProvisioningCriteriaTemplate.glAccounts:'id':'name') || '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let definition">
            <button mat-button color="primary" (click)="editDefinition(definition)">
              <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
              Edit
            </button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      </table>

    </div>    

    <div class="m-t-20" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
      <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="!provisioningCriteriaFormValid" (click)="submit()" *mifosxHasPermission="'CREATE_PROVISIONING_CRITERIA'">Submit</button>
    </div>

  </mat-card>

</div>
