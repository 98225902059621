<div class="tab-container mat-typography">

    <h3>Identities</h3>

    <button mat-raised-button class="f-right" color="primary" (click)="addIdentifier()" *mifosxHasPermission="'CREATE_CLIENTIDENTIFIER'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp; Add
    </button>

    <table mat-table #identifiersTable [dataSource]="clientIdentities">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Id </th>
          <td mat-cell *matCellDef="let identity">{{identity.id}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let identity"> {{identity.description}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> type </th>
          <td mat-cell *matCellDef="let identity"> {{identity.documentType.name}}</td>
        </ng-container>

        <ng-container matColumnDef="documents">
          <th mat-header-cell *matHeaderCellDef>Identity Documents </th>
          <td mat-cell *matCellDef="let identity">
            <div *ngFor="let document of identity.documents">
              {{document.name}}
              <fa-icon icon="cloud-download-alt" (click)="download(document.parentEntityId,document.id)" *mifosxHasPermission="'READ_DOCUMENT'"></fa-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let identity">
              {{(identity.status==='clientIdentifierStatusType.active')?'active':'inactive'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let identity; let i = index">
            <button class="identity-action-button" mat-raised-button color="primary" (click)="uploadDocument(i,identity.id)" *mifosxHasPermission="'CREATE_DOCUMENT'">
              <fa-icon icon="cloud-upload-alt"></fa-icon>
            </button>
            <button class="identity-action-button" mat-raised-button color="warn" (click)="deleteIdentifier(identity.clientId,identity.id,i)" *mifosxHasPermission="'DELETE_CLIENTIDENTIFIER'">
              <fa-icon icon="times"></fa-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="identitiesColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: identitiesColumns;"></tr>

    </table>

</div>