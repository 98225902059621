<p>Please update your password:</p>

<mat-divider></mat-divider>

<form fxLayout="column" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" id="reset-password-form">

  <mat-form-field fxFlexAlign="center" class="reset-password-input">
    <span matPrefix>
      <fa-icon icon="lock" size="lg"></fa-icon>&nbsp;&nbsp;
    </span>
    <mat-label>Password</mat-label>
    <input type="{{ passwordInputType }}" matInput required formControlName="password">
    <button mat-button *ngIf="resetPasswordForm.controls.password.value && !loading" matSuffix mat-icon-button
      (mousedown)="passwordInputType = 'text'" (mouseup)="passwordInputType = 'password'">
      <fa-icon *ngIf="passwordInputType === 'password'" icon="eye"></fa-icon>
      <fa-icon *ngIf="passwordInputType === 'text'" icon="eye-slash"></fa-icon>
    </button>
    <mat-error *ngIf="resetPasswordForm.controls.password.hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlexAlign="center" class="reset-password-input">
    <span matPrefix>
      <fa-icon icon="check-circle" size="lg"></fa-icon>&nbsp;&nbsp;
    </span>
    <mat-label>Confirm Password</mat-label>
    <input type="password" matInput required formControlName="repeatPassword">
    <mat-error *ngIf="resetPasswordForm.controls.repeatPassword.hasError('required')">
      Confirm Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <p class="reset-password-error" *ngIf="resetPasswordForm.errors?.passwordsDoNotMatch &&
    (!resetPasswordForm.controls.password.hasError('required') && !resetPasswordForm.controls.repeatPassword.hasError('required'))">
    Passwords <strong>do not match</strong>
  </p>

  <button mat-raised-button color="primary" fxFlexAlign="center" class="reset-password-button" [disabled]="!resetPasswordForm.valid">
    Reset Password
    <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
  </button>

</form>
