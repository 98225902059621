<div class="container">

  <mat-card>

    <form [formGroup]="centerForm">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="centerForm.controls.name.hasError('required')">
              Center Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="centerForm.controls.name.hasError('pattern')">
              Center Name <strong>cannot</strong> begin with a special character or number
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Office</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="centerForm.controls.officeId.hasError('required')">
              Office is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Staff</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of staffData" [value]="staff.id">
                {{ staff.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="active">
            Active
          </mat-checkbox>

          <mat-form-field *ngIf="this.centerForm.controls.active.value">
            <mat-label>Activation Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="activationDatePicker" required formControlName="activationDate">
            <mat-datepicker-toggle matSuffix [for]="activationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #activationDatePicker></mat-datepicker>
            <mat-error *ngIf="centerForm.controls.activationDate.hasError('required')">
               Activation Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>External id</mat-label>
            <input matInput formControlName="externalId">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Submitted on</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="submittedOnDatePicker" required formControlName="submittedOnDate">
            <mat-datepicker-toggle matSuffix [for]="submittedOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #submittedOnDatePicker></mat-datepicker>
            <mat-error *ngIf="centerForm.controls.submittedOnDate.hasError('required')">
              Submission Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Select and Add groups</mat-label>
            <mat-select [formControl]="groupChoice">
              <mat-option *ngFor="let group of groupsData" [value]="group">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div fxLayout="row">

          <div class="mat-table" *ngIf="groupChoice.value">
            <div class="mat-header-row">
              <div class="mat-header-cell">Group Details</div>
              <button mat-icon-button (click)="addGroup()">
                <fa-icon icon="plus"></fa-icon>
              </button>
            </div>
            <div class="mat-row">
              <div class="mat-cell">Name</div>
              <div class="mat-cell">{{groupChoice.value.name}}</div>
            </div>
            <div class="mat-row">
              <div class="mat-cell">Id</div>
              <div class="mat-cell">{{groupChoice.value.id}}</div>
            </div>
            <div class="mat-row">
              <div class="mat-cell">Office</div>
              <div class="mat-cell">{{groupChoice.value.officeName}}</div>
            </div>
          </div>

          <mat-nav-list *ngIf="groupMembers.length">
            <h3 matSubheader>Selected Groups</h3>
            <div mat-list-item *ngFor="let group of groupMembers index as i">
              <button mat-icon-button (click)="removeGroup(i)">
                <fa-icon icon="trash"></fa-icon>
              </button>
              <span matLine>{{group.name}}</span>
            </div>
          </mat-nav-list>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!centerForm.valid" (click)="submit()" *mifosxHasPermission="'CREATE_CENTER'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
