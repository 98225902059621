<div class="container" *ngIf="provisioningEntry">

  <div class="m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
    <button mat-raised-button color="primary" (click)="createProvisioningJournalEntries()" [disabled]="provisioningEntry.journalEntry" *mifosxHasPermission="'CREATE_JOURNAL_ENTRY'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      Create Journal Entries
    </button>
  </div>

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content" fxLayout.lt-md="column">

        <div fxFlex="16%" class="header">
          Created By
        </div>

        <div fxFlex="16%">
          {{ provisioningEntry.createdUser }}
        </div>

        <div fxFlex="16%" class="header">
          Created On
        </div>

        <div fxFlex="16%">
          {{ provisioningEntry.createdDate }}
        </div>

        <div fxFlex="18%" class="header">
          Amount to be Reserved
        </div>

        <div fxFlex="18%">
          {{ provisioningEntry.reservedAmount }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>

<!-- Search -->
<div class="container m-t-20" fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

  <mat-form-field fxFlex="31%">
    <mat-label>Office Name</mat-label>
    <input matInput [formControl]="officeName" [matAutocomplete]="officeNameAutocomplete">
  </mat-form-field>

  <mat-form-field fxFlex="31%">
    <mat-label>Loan Product</mat-label>
    <input matInput [formControl]="loanProduct" [matAutocomplete]="loanProductAutocomplete">
  </mat-form-field>

  <mat-form-field fxFlex="31%">
    <mat-label>Provisioning Category</mat-label>
    <input matInput [formControl]="provisioningCategory" [matAutocomplete]="provisioningCategoryAutocomplete">
  </mat-form-field>

</div>

<!-- Autocomplete data -->
<mat-autocomplete autoActiveFirstOption #officeNameAutocomplete="matAutocomplete">
  <mat-option *ngFor="let office of filteredOfficeData | async" [value]="office.name">
    {{ office.name }}
  </mat-option>
</mat-autocomplete>

<mat-autocomplete autoActiveFirstOption #loanProductAutocomplete="matAutocomplete">
  <mat-option *ngFor="let loanProduct of filteredLoanProductData | async" [value]="loanProduct.name">
    {{ loanProduct.name }}
  </mat-option>
</mat-autocomplete>

<mat-autocomplete autoActiveFirstOption #provisioningCategoryAutocomplete="matAutocomplete">
  <mat-option *ngFor="let provisioningCategory of filteredProvisioningCategoryData | async" [value]="provisioningCategory.categoryName">
    {{ provisioningCategory.categoryName }}
  </mat-option>
</mat-autocomplete>

<!--Table -->

<div class="mat-elevation-z8 container">

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="officeName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Office Name </th>
      <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.officeName }} </td>
    </ng-container>

    <ng-container matColumnDef="productName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
      <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.productName }} </td>
    </ng-container>

    <ng-container matColumnDef="currencyCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Currency Code </th>
      <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.currencyCode }} </td>
    </ng-container>

    <ng-container matColumnDef="categoryName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Category Name </th>
      <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.categoryName }} </td>
    </ng-container>

    <ng-container matColumnDef="amountreserved">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Provisioning Amount </th>
      <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.amountreserved }} </td>
    </ng-container>

    <ng-container matColumnDef="liabilityAccountName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Liability Account (CR) </th>
      <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.liabilityAccountName }} </td>
    </ng-container>

    <ng-container matColumnDef="expenseAccountName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Expense Account (DR) </th>
      <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.expenseAccountName }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

</div>
