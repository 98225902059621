<div class="container">

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let configuration"> {{ configuration.name }}&nbsp;&nbsp;
        <fa-icon icon="question-circle" matTooltip="{{ configuration.description === undefined ? 'No Description' : configuration.description }}" matTooltipPosition="right"></fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let configuration">
          <div [className]="configuration.enabled ? 'enabled' : 'disabled'">
            <fa-icon icon="circle" size="lg" matTooltip="{{ configuration.enabled ? 'Enabled' : 'Disabled' }}" matTooltipPosition="right"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
        <td mat-cell *matCellDef="let configuration"> {{ configuration.value }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let configuration">
          <button mat-button *ngIf="!configuration.enabled" color="accent" (click)="toggleStatus(configuration)">
            <fa-icon icon="lock-open"></fa-icon>&nbsp;&nbsp;Enable
          </button>
          <button mat-button *ngIf="configuration.enabled" color="warn" (click)="toggleStatus(configuration)">
            <fa-icon icon="lock"></fa-icon>&nbsp;&nbsp;Disable
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td mat-cell *matCellDef="let configuration">
          <button mat-button color="primary" [routerLink]="[configuration.id, 'edit']">
            <fa-icon icon="edit"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
