/** Angular Imports */
import { Component, OnInit } from '@angular/core';

/**
 * Products component.
 */
@Component({
  selector: 'mifosx-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
