<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_PAYMENTTYPE'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Create Payment Type
  </button>

</div>

<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let paymentType"> {{ paymentType.name }} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let paymentType"> {{ paymentType.description }} </td>
      </ng-container>

      <ng-container matColumnDef="isCashPayment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cash Payment </th>
        <td mat-cell *matCellDef="let paymentType">
          <div [className]="paymentType.isCashPayment === true ? 'true' : 'false'">
            <fa-icon *ngIf="paymentType.isCashPayment" icon="check-circle" size="lg" matTooltip="Yes" matTooltipPosition="right"></fa-icon>
            <fa-icon *ngIf="!paymentType.isCashPayment" icon="times-circle" size="lg" matTooltip="No" matTooltipPosition="right"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Position </th>
        <td mat-cell *matCellDef="let paymentType"> {{ paymentType.position }} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let paymentType">
          <button mat-button [routerLink]="[paymentType.id, 'edit']" color="primary">
            <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;Edit
          </button>
          <button mat-button (click)="deletePaymentType(paymentType.id)" color="warn">
            <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
