<div class="container">

  <mat-card>

    <form [formGroup]="workingDaysForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <div fxLayout="row" fxLayout.lt-md="column">
            <mat-label fxFlex="50%" class="mat-h4">Working Days</mat-label>
            <div fxFlex="50%" fxLayout="column" formArrayName="recurrence">
              <div *ngFor="let day of recurrence.controls; let i = index;">
                <mat-checkbox labelPosition="after" [formControlName]="i">
                  {{ weekDays[i].name }}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <mat-form-field>
            <mat-label>Payments due on non working days</mat-label>
            <mat-select formControlName="repaymentRescheduleType">
              <mat-option *ngFor="let repaymentRescheduleType of repaymentRescheduleTypeData" [value]="repaymentRescheduleType.id">
                {{ repaymentRescheduleType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="extendTermForDailyRepayments">
            Extend the term for loans following a daily repayment schedule
          </mat-checkbox>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="workingDaysForm.pristine" *mifosxHasPermission="'UPDATE_WORKINGDAYS'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
