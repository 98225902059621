<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_REPORT'">
        <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
        Create Report
    </button>
</div>

<div class="container">

    <div fxLayout="row">
        <mat-form-field fxFlex>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
    </div>

    <div class="mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="reportName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Report Name </th>
                <td mat-cell *matCellDef="let report"> {{ report.reportName }} </td>
            </ng-container>

            <ng-container matColumnDef="reportType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Report Type </th>
                <td mat-cell *matCellDef="let report"> {{ report.reportType }} </td>
            </ng-container>

            <ng-container matColumnDef="reportSubType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Report Sub Type </th>
                <td mat-cell *matCellDef="let report"> {{ report.reportSubType }} </td>
            </ng-container>

            <ng-container matColumnDef="reportCategory">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Report Category </th>
                <td mat-cell *matCellDef="let report"> {{ report.reportCategory }} </td>
            </ng-container>

            <ng-container matColumnDef="coreReport">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Core Report </th>
                <td mat-cell *matCellDef="let report">
                    <fa-icon *ngIf="report.coreReport" icon="check-circle" size="lg" class="true" matTooltip="Yes"
                        matTooltipPosition="right"></fa-icon>
                    <fa-icon *ngIf="!report.coreReport" icon="times-circle" size="lg" class="false" matTooltip="No"
                        matTooltipPosition="right"></fa-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="userReport">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User Report </th>
                <td mat-cell *matCellDef="let report">
                    <fa-icon *ngIf="report.useReport" icon="check-circle" size="lg" class="true" matTooltip="Yes"
                        matTooltipPosition="right"></fa-icon>
                    <fa-icon *ngIf="!report.useReport" icon="times-circle" size="lg" class="false" matTooltip="No"
                        matTooltipPosition="right"></fa-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]"
                class="select-row"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>
</div>
