/** Angular Imports */
import { Component, OnInit } from '@angular/core';

/**
 * Accounting component.
 */
@Component({
  selector: 'mifosx-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
