<form [formGroup]="loanProductAccountingForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-radio-group fxFlex="98%" fxLayout="row" fxLayoutGap="5%" fxLayout.lt-md="column" formControlName="accountingRule">
      <mat-radio-button *ngFor="let accountingRule of accountingRuleData; let i =  index" [value]="i+1">
        {{ accountingRule }}
      </mat-radio-button>
    </mat-radio-group>

    <mat-divider fxFlex="98%"></mat-divider>

    <div *ngIf="loanProductAccountingForm.value.accountingRule >= 2 && loanProductAccountingForm.value.accountingRule <= 4" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <h4 fxFlex="98%" class="mat-h4">Assets</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Fund source</mat-label>
        <mat-select formControlName="fundSourceAccountId" required>
          <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
            {{ assetAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Fund source is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>Loan portfolio</mat-label>
        <mat-select formControlName="loanPortfolioAccountId" required>
          <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
            {{ assetAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Loan portfolio is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <div *ngIf="loanProductAccountingForm.value.accountingRule === 3 || loanProductAccountingForm.value.accountingRule === 4" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <mat-form-field fxFlex="48%">
          <mat-label>Interest Receivable</mat-label>
          <mat-select formControlName="receivableInterestAccountId" required>
            <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
              {{ assetAccount.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            Interest Receivable is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48%">
          <mat-label>Fees Receivable</mat-label>
          <mat-select formControlName="receivableFeeAccountId" required>
            <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
              {{ assetAccount.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            Fees Receivable is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48%">
          <mat-label>Penalties Receivable</mat-label>
          <mat-select formControlName="receivablePenaltyAccountId" required>
            <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
              {{ assetAccount.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            Penalties Receivable is <strong>required</strong>
          </mat-error>
        </mat-form-field>

      </div>

      <mat-form-field fxFlex="48%">
        <mat-label>Transfer in suspense</mat-label>
        <mat-select formControlName="transfersInSuspenseAccountId" required>
          <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
            {{ assetAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Transfer in suspense is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">Income</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Income from Interest</mat-label>
        <mat-select formControlName="interestOnLoanAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Income from Interest is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>Income from fees</mat-label>
        <mat-select formControlName="incomeFromFeeAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Income from fees is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>Income from penalties</mat-label>
        <mat-select formControlName="incomeFromPenaltyAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Income from penalties is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>Income from Recovery Repayments</mat-label>
        <mat-select formControlName="incomeFromRecoveryAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Income from Recovery Repayments is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">Expenses</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Losses written off</mat-label>
        <mat-select formControlName="writeOffAccountId" required>
          <mat-option *ngFor="let expenseAccount of expenseAccountData" [value]="expenseAccount.id">
            {{ expenseAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Losses written off is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">Liabilities</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Over payment liability</mat-label>
        <mat-select formControlName="overpaymentLiabilityAccountId" required>
          <mat-option *ngFor="let liabilityAccount of liabilityAccountData" [value]="liabilityAccount.id">
            {{ liabilityAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Over payment liability is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h3 fxFlex="23%" class="mat-h3">Advanced Accounting Rules</h3>

      <mat-checkbox fxFlex="73%" formControlName="advancedAccountingRules"></mat-checkbox>

      <div *ngIf="loanProductAccountingForm.value.advancedAccountingRules" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <h4 fxFlex="33%" class="mat-h4">Configure Fund Sources for Payment Channels</h4>

        <div fxFlex="63%">
          <button type="button" mat-raised-button color="primary" (click)="add('PaymentFundSource', paymentChannelToFundSourceMappings)">
            <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
            Add
          </button>
        </div>

        <table fxFlex="98%" class="mat-elevation-z1" mat-table [dataSource]="paymentChannelToFundSourceMappings.value" *ngIf="paymentChannelToFundSourceMappings.value.length !== 0">

          <ng-container matColumnDef="paymentTypeId">
            <th mat-header-cell *matHeaderCellDef> Payment Type </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.paymentTypeId | find:paymentTypeData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fundSourceAccountId">
            <th mat-header-cell *matHeaderCellDef> Fund Source </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.fundSourceAccountId | find:assetAccountData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let paymentFundSource; let i = index">
              <button mat-icon-button color="primary" (click)="edit('PaymentFundSource', paymentChannelToFundSourceMappings, i)">
                <fa-icon icon="edit"></fa-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete(paymentChannelToFundSourceMappings, i)">
                <fa-icon icon="trash"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

        </table>

        <h4 fxFlex="33%" class="mat-h4">Map Fees to Specific Income Accounts</h4>

        <div fxFlex="63%">
          <button type="button" mat-raised-button color="primary" (click)="add('FeesIncome', feeToIncomeAccountMappings)">
            <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
            Add
          </button>
        </div>

        <table fxFlex="98%" class="mat-elevation-z1" mat-table [dataSource]="feeToIncomeAccountMappings.value" *ngIf="feeToIncomeAccountMappings.value.length !== 0">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef> Fees </th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.chargeId | find:chargeData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef> Income Account </th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.incomeAccountId | find:incomeAndLiabilityAccountData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let feesIncome; let i = index">
              <button mat-icon-button color="primary" (click)="edit('FeesIncome', feeToIncomeAccountMappings, i)">
                <fa-icon icon="edit"></fa-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete(feeToIncomeAccountMappings, i)">
                <fa-icon icon="trash"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

        <h4 fxFlex="33%" class="mat-h4">Map Penalties to Specific Income Accounts</h4>

        <div fxFlex="63%">
          <button type="button" mat-raised-button color="primary" (click)="add('PenaltyIncome', penaltyToIncomeAccountMappings)">
            <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
            Add
          </button>
        </div>


        <table fxFlex="98%" class="mat-elevation-z1" mat-table [dataSource]="penaltyToIncomeAccountMappings.value" *ngIf="penaltyToIncomeAccountMappings.value.length !== 0">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef> Penalty </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.chargeId | find:penaltyData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef> Income Account </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.incomeAccountId | find:incomeAccountData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let penaltyIncome; let i = index">
              <button mat-icon-button color="primary" (click)="edit('PenaltyIncome', penaltyToIncomeAccountMappings, i)">
                <fa-icon icon="edit"></fa-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete(penaltyToIncomeAccountMappings, i)">
                <fa-icon icon="trash"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

    </div>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      Previous
    </button>
    <button mat-raised-button matStepperNext [disabled]="!loanProductFormValid">
      Next&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
