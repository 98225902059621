<div class="container m-b-20" fxLayout="row" fxLayoutGap="20px">
  <h2 class="no-m">Scheduler Status: {{ schedulerData.active === true ? 'Active' : 'Inactive' }}</h2>
  <button mat-raised-button class="suspend">
    <fa-icon icon="times-circle"></fa-icon>&nbsp;&nbsp;
    Suspend
  </button>
</div>

<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let job"> {{ job.displayName }} </td>
      </ng-container>

      <ng-container matColumnDef="nextRunTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Next Run </th>
        <td mat-cell *matCellDef="let job"> {{ job.nextRunTime }} </td>
      </ng-container>

      <ng-container matColumnDef="previousRunTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Previous Run </th>
        <td mat-cell *matCellDef="let job"> {{ job.lastRunHistory.jobRunStartTime }} </td>
      </ng-container>

      <ng-container matColumnDef="previousRunStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Previous Run Status </th>
        <td mat-cell *matCellDef="let job">
          <fa-icon *ngIf="job.lastRunHistory.status === 'success'" class="success" matTooltip="Successful" matTooltipPosition="right" icon="check-circle" size="lg"></fa-icon>
          <fa-icon *ngIf="!(job.lastRunHistory.status == 'success')" class="fail" matTooltip="Failed" matTooltipPosition="right" icon="times-circle" size="lg"></fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="currentlyRunning">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Currently Running </th>
        <td mat-cell *matCellDef="let job">
          <div [className]="job.currentlyRunning === true ? 'currently-running' : 'not-currently-running'">
              <fa-icon matTooltip="{{ job.currentlyRunning === true ? 'Yes' : 'No' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="errorLog">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Error Log </th>
        <td mat-cell *matCellDef="let job">
          <button mat-icon-button class="errorlog" *ngIf="!(job.lastRunHistory.status == 'success')" matTooltip="Error Log" matTooltipPosition="right" >
              <fa-icon icon="exclamation-circle" size="lg"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.jobId]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<div class="container" fxLayout="row" fxLayoutGap="20px">
  <button mat-raised-button color="primary">
    <fa-icon icon="play"></fa-icon>&nbsp;&nbsp;
    Run Selected Jobs
  </button>
  <button mat-raised-button color="primary">
    <fa-icon icon="sync"></fa-icon>&nbsp;&nbsp;
    Refresh
  </button>
</div>
