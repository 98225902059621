<div class="container">

  <mat-card>

    <form [formGroup]="reportForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="4%">

          <mat-form-field fxFlex="30%">
            <mat-label>Report Name</mat-label>
            <input matInput required formControlName="reportName">
            <mat-error *ngIf="reportForm.controls.reportName.hasError('required')">
              Report Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="30%">
            <mat-label>Report Type</mat-label>
            <mat-select required formControlName="reportType">
              <mat-option *ngFor="let allowedReportType of reportTemplateData.allowedReportTypes"
                [value]="allowedReportType">
                {{ allowedReportType }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="reportForm.controls.reportType.hasError('required')">
              Report Type is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="30%">
            <mat-label>Report Sub Type</mat-label>
            <mat-select formControlName="reportSubType">
              <mat-option *ngFor="let allowedReportSubType of reportTemplateData.allowedReportSubTypes"
                [value]="allowedReportSubType">
                {{ allowedReportSubType }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="4%">

          <mat-form-field fxFlex="40%">
            <mat-label>Report Category</mat-label>
            <input matInput formControlName="reportCategory">
          </mat-form-field>

          <div fxFlex="10%" class="user-report-wrapper">
            <mat-checkbox class="user-report" labelPosition="before" formControlName="useReport">
              User Report (UI)
            </mat-checkbox>
          </div>

        </div>

        <div fxLayout="column">

          <mat-form-field fxFlex="98%">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>

          <mat-form-field fxFlex="98%">
            <mat-label>SQL</mat-label>
            <textarea matInput required formControlName="reportSql"></textarea>
            <mat-error *ngIf="reportForm.controls.reportSql.hasError('required')">
              SQL is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

        <br>

        <div fxLayout="row wrap" fxLayoutGap="60%" fxLayout.lt-md="column">

          <p fxFlex="20%" class="mat-title">Report Parameters</p>

          <button mat-raised-button fxFlex="20%" type="button" color="primary" (click)="addReportParameter()">
            <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
            Add Report Parameter
          </button>

        </div>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="parameterName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Parameter </th>
            <td mat-cell *matCellDef="let reportParameter"> {{ reportParameter.parameterName }} </td>
          </ng-container>

          <ng-container matColumnDef="parameterNamePassed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Parameter Name Passed to Pentaho </th>
            <td mat-cell *matCellDef="let reportParameter"> {{ reportParameter.reportParameterName }} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let reportParameter" fxLayoutGap="15%">
              <button type="button" fxFlex="8%" color="primary" mat-icon-button
                (click)="editReportParameter(reportParameter)">
                <fa-icon icon="edit" size="lg"></fa-icon>
              </button>
              <button type="button" fxFlex="8%" color="warn" mat-icon-button
                (click)="deleteReportParameter(reportParameter)">
                <fa-icon icon="trash" size="lg"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button type="submit" color="primary"
          [disabled]="!reportForm.valid && reportParametersData.length === 0" *mifosxHasPermission="'CREATE_REPORT'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
